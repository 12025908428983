import React, { useContext } from 'react';
import AuthState, { AuthContext } from './auth';
import ProfileState, { ProfileContext } from './profiles';
import LoadingState, { LoadingContext } from './loading';
import CategorySate, { CategoryContext } from './cateogries';
import ToastState, { ToastContext } from './toasts';
import CourseState, { CourseContext } from './courses';

export const useGlobalContext = () => ({
  auth: useContext(AuthContext),
  profiles: useContext(ProfileContext),
  loading: useContext(LoadingContext),
  categories: useContext(CategoryContext),
  toasts: useContext(ToastContext),
  courses: useContext(CourseContext),
});

const globalState = ({ children }) => {
  return (
    <ToastState>
      <LoadingState>
        <AuthState>
          <CategorySate>
            <ProfileState>
              <CourseState>{children}</CourseState>
            </ProfileState>
          </CategorySate>
        </AuthState>
      </LoadingState>
    </ToastState>
  );
};

export default globalState;
