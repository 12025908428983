import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { css } from 'emotion';
import theme from '../../theme/theme';
import logo from '../../assets/img/torahEducationLogo 1.png';
import { useGlobalContext } from '../../context';

const Nav = () => {
  const {
    auth: { logout, isAdmin },
  } = useGlobalContext();
  const styles = {
    primaryBack: css({
      backgroundColor: theme.colors.primary,
      '& .nav-link': {
        color: '#fff',
      },
    }),
  };
  return (
    <nav
      className={`${styles.primaryBack} navbar navbar-expand-lg  navbar-light`}
    >
      <Link className='navbar-brand' to='/'>
        <img src={logo} alt='' />
      </Link>
      <button
        className='navbar-toggler'
        type='button'
        data-toggle='collapse'
        data-target='#navbarNav'
        aria-controls='navbarNav'
        aria-expanded='false'
        aria-label='Toggle navigation'
      >
        <span className='navbar-toggler-icon'></span>
      </button>
      <div className='collapse navbar-collapse' id='navbarNav'>
        <ul className='navbar-nav float-right'>
          <li className='nav-item'>
            <NavLink
              activeClassName='active'
              className='nav-link'
              to='/profiles'
            >
              Profiles
            </NavLink>
          </li>
          <li className='nav-item'>
            <NavLink
              activeClassName='active'
              className='nav-link'
              to='/courses'
            >
              Courses
            </NavLink>
          </li>
          {/* <li className='nav-item'>
            <NavLink activeClassName='active' className='nav-link' to='/books'>
              Books
            </NavLink>
          </li>
          <li className='nav-item'>
            <NavLink
              activeClassName='active'
              className='nav-link'
              to='/lectures'
              aria-disabled='true'
            >
              Lectures
            </NavLink>
          </li> */}
          {isAdmin && (
            <>
              <li className='nav-item'>
                <NavLink
                  activeClassName='active'
                  className='nav-link'
                  to='/categories'
                  aria-disabled='true'
                >
                  Categories
                </NavLink>
              </li>
            </>
          )}
          <li className='nav-item'>
            <a
              className='nav-link'
              href='#!'
              onClick={(e) => {
                e.preventDefault();
                logout();
              }}
              aria-disabled='true'
            >
              Logout
            </a>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Nav;
