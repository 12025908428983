import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Login from './components/auth/Login';
import GlobalState from './context';
import Nav from './components/Layout/Nav';
// import NotFoundRoute from './components/Layout/404';
import ProfileList from './components/profiles/ProfileList';
import Loading from './components/Layout/Loading';
import PrivateRoute from './components/auth/PrivateRoute';
import AdminRoute from './components/auth/AdminRoute';
import ProfileData from './components/profiles/profileForm/ProfileData';
import CategoryList from './components/categories/CategoryList';
import CategoryData from './components/categories/CategoryForm/CategoryData';
import { ToastContainer } from 'react-toastify';
import Footer from './components/Layout/Footer';
import LogRocket from 'logrocket';
import config from './config';
import CourseData from './components/courses/courseForm/CourseData';
import CourseList from './components/courses/CourseList';

function App() {
  useEffect(() => {
    if (config.LOG_ROCKET) LogRocket.init(config.LOG_ROCKET);

    window.addEventListener('keydown', function (event) {
      if (event.code == 'Enter') {
        event.preventDefault();
      }
    });
  }, []);
  return (
    <Router>
      {' '}
      <GlobalState>
        <Switch>
          <Route exact path='/' component={Login} />
          <Route>
            <Nav />
            <div className='container'>
              <Switch>
                <PrivateRoute exact path='/profiles' component={ProfileList} />
                <PrivateRoute
                  exact
                  path='/profiles/:profile_id'
                  component={ProfileData}
                />
                <PrivateRoute exact path='/courses' component={CourseList} />
                <PrivateRoute
                  exact
                  path='/courses/:course_id'
                  component={CourseData}
                />
                <AdminRoute exact path='/categories' component={CategoryList} />
                <AdminRoute
                  exact
                  path='/categories/:category_id'
                  component={CategoryData}
                />
              </Switch>
            </div>
            <Footer />
          </Route>
        </Switch>
        <Loading />
        <ToastContainer autoClose={10000} />
      </GlobalState>
    </Router>
  );
}

export default App;
