import React from 'react';
import { css } from 'emotion';
import { ShortArrowLeft, ArrowLeft, ArrowRight, Eye } from '../../Layout/icons';
import { Link } from 'react-router-dom';
import config from '../../../config';

const ProfileHeader = ({ status, didSomethingChange, profile, onSubmit }) => {
  const styles = {
    container: css({
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
    }),
  };

  //   const btnStatusColors = {
  //     published: 'success',
  //     draft: 'secondary',
  //     unpublished: 'warning',
  //   };

  const statuses = {
    draft: {
      otherStatuses: [
        { label: 'Publish profile', name: 'published' },
        { label: 'Unpaid listing only', name: 'unpaid-listing-only' },
        { label: 'Listing only', name: 'listing-only' },
      ],
      currentStatus: { label: 'Save Changes', name: 'draft' },
    },
    published: {
      otherStatuses: [
        { label: 'Unpublish profile', name: 'unpublished' },
        { label: 'Unpaid listing only', name: 'unpaid-listing-only' },
        { label: 'Listing only', name: 'listing-only' },
      ],
      currentStatus: { label: 'Publish changes' },
    },
    unpublished: {
      otherStatuses: [
        { label: 'Publish profile', name: 'published' },
        { label: 'Unpaid listing only', name: 'unpaid-listing-only' },
        { label: 'Listing only', name: 'listing-only' },
      ],
      currentStatus: { label: 'Save Changes', name: 'unpublished' },
    },
    ['unpaid-listing-only']: {
      currentStatus: {
        label: 'Unpaid listing only',
        name: 'unpaid-listing-only',
      },
      otherStatuses: [
        { label: 'Publish profile', name: 'published' },
        { label: 'Unpublish profile', name: 'unpublished' },
        { label: 'Listing only', name: 'listing-only' },
      ],
    },
    ['listing-only']: {
      currentStatus: { label: 'Listing only', name: 'listing-only' },
      otherStatuses: [
        { label: 'Publish profile', name: 'published' },
        { label: 'Unpublish profile', name: 'unpublished' },
        { label: 'Unpaid listing only', name: 'unpaid-listing-only' },
      ],
    },
  };

  return (
    <div className={styles.container + ' my-3'}>
      <div>
        <Link to='/profiles' className='btn btn-link '>
          <ShortArrowLeft /> Back to profiles
        </Link>
        {/* <button type='button' className='btn btn-link disabled'>
          <ArrowLeft /> Previous Profile
        </button>
        <button type='button' className='btn btn-link disabled'>
          Next profile <ArrowRight />
        </button> */}
      </div>
      <div>
        {' '}
        <a
          type='button'
          className={`btn btn-link ${profile ? '' : 'disabled'}`}
          href={config.FRONTEND_URL + '/profiles/' + profile?._id}
          target='_blank'
        >
          <Eye /> Preview
        </a>
        {/* <button
          type='button'
          type='button'
          className={`btn btn-primary ${didSomethingChange ? '' : 'disabled'}`}
        >
          <Save /> Save
        </button> */}
        <div className='btn-group' role='group'>
          <button
            type='submit'
            className={`btn btn-primary ${
              didSomethingChange ? '' : 'disabled'
            }`}
            onClick={(e) => onSubmit(e)}
          >
            {' '}
            {statuses[status].currentStatus.label}
          </button>
          <button
            id='btnGroupDrop1'
            type='button'
            className={`btn btn-primary dropdown-toggle dropdown-toggle-split `}
            data-toggle='dropdown'
          >
            {' '}
            <span className='sr-only'>Toggle Dropdown</span>
          </button>
          <div className='dropdown-menu' aria-labelledby='btnGroupDrop1'>
            {statuses[status].otherStatuses.map((e, i) => (
              <button
                type='submit'
                key={i}
                className='dropdown-item'
                href='!#'
                onClick={(event) => onSubmit(event, e.name)}
              >
                {e.label}
              </button>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileHeader;
